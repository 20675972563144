<script setup>
import GlobalEmitter from '~/glxp/utils/emitter'
import { useLoaderStore } from '~/store/loader'

const route = useRoute()
console.log(route)
const slug = route.params.slug != undefined && route.params.slug ?  route.params.slug.replace(/-/g, ' ') : 'Error  | Virgin Galactic'
const slugTitle = slug.charAt(0).toUpperCase() + slug.slice(1)
useHead({
  title:  slugTitle ,
})
const props = defineProps({
  error: {
    type: Object,
    required: true,
  },
})

const loaderState = useLoaderStore()
onMounted(() => {
  GlobalEmitter.emit('webgl_raf_stop')
  GlobalEmitter.emit('webgl_loading_progress', { progress: 100 })
  loaderState.isOpen = false
})

const { t } = useI18n()
const message = computed(() =>
  props.error.statusCode === 404 ? t('error.404') : t('error.500')
)
</script>

<template>
  <article class="page-error">
    <div class="page-error__wrapper">
      <SectionHeader
        :eyebrow="`${error.statusCode}`"
        :title="message"
        size="s"
        level="h1"
        :copy="message"
      />

      <ButtonPill icon="chevron-right" big> Go home </ButtonPill>
    </div>
  </article>
</template>

<style lang="scss">
// Vendors
@import 'styles/vendors/sass-mq/mq';
@import 'styles/utils/functions';

.page-error {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  &::before {
    content: '';
    background-image: radial-gradient(
        62.22% 94.81% at 99.13% 100%,
        rgba(200, 10, 251, 0.15) 0%,
        rgba(200, 10, 251, 0) 100%
      ),
      radial-gradient(
        50.05% 50.05% at 50% 100%,
        rgba(21, 42, 244, 0.22) 0%,
        rgba(21, 42, 244, 0) 100%
      ),
      radial-gradient(
        60.33% 52.94% at 59.41% 9.23%,
        rgba(13, 6, 29, 0) 0%,
        #0d061d 100%
      ),
      url('~/assets/images/error/background-desktop.png');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: auto, auto, auto, cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 90px;
    padding-bottom: 40px;

    .button-pill {
      margin-top: 60px;
    }
  }

  .section-header {
    position: relative;
    z-index: 2;

    .horizontal-rule {
      margin-bottom: 0px;
    }

    &__headline {
      max-width: 30vw;
      min-width: 300px;
    }
  }
}
</style>
